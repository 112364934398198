import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import FadeInOnView from "@components/FadeInOnView";
import Container from "@components/Container";
import ContactLine from "@components/ContactLine";
import Footer from "@components/Footer";
import ContactHero from "@components/ContactHero";

import useUIColorCheck from "@utils/useUIColorCheck";
import { Helmet } from "react-helmet";

const Contact = ({ firstAddress, secondAddress, transitionStatus }) => {
  useUIColorCheck([transitionStatus === "entered"]);

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      wpgraphql {
        rollStudioOptions {
          acf {
            firstAddress {
              cityPostcode
              email
              streetName
            }
            secondAddress {
              cityPostcode
              email
              streetName
            }
          }
        }
      }
    }
  `);

  const acf = data?.wpgraphql?.rollStudioOptions?.acf;
  firstAddress = acf?.firstAddress ?? firstAddress;
  secondAddress = acf?.secondAddress ?? secondAddress;

  /* const [isModalOpen, setIsModalOpen] = useState(true); */

  return (
    <>
      <Helmet title={"Contact | " + data.site.siteMetadata.title} />
      <FadeInOnView className="page contact-page">
        <Container variant="full">
          <ContactHero />
          <ContactLine />
        </Container>
      </FadeInOnView>
      <Footer />
    </>
  );
};

Contact.propTypes = {
  firstAddress: PropTypes.object,
  secondAddress: PropTypes.object,
};

export default Contact;
