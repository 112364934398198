import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import data from "./data.json";

function SayHello() {
  const [index, setIndex] = useState(0);
  const [text, setText] = useState("");

  const letterPrint = useCallback(
    index => {
      const letter = data.says[index].key;

      switch (letter) {
        case "Backspace":
          setText(text.slice(0, -1));
          break;
        case "Enter":
        case "Shift":
        case "CapsLock":
          break;
        default:
          setText(text + letter);
      }
    },
    [text]
  );

  useEffect(() => {
    const delay =
      index === 0 ? 1000 : data.says[index].time - data.says[index - 1].time;
    const timeout = setTimeout(() => {
      letterPrint(index);

      if (index >= data.says.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [index, letterPrint]);

  return <h1 id="result">{text}</h1>;
}
export default SayHello;
