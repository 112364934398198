import React, { useContext } from "react";

import ModalContext from "@components/ModalContext";
import SayHello from "@components/SayHello";
import Grid from "@components/Grid";

import "./style.css";

function ContactHero() {
  const modal = useContext(ModalContext);

  const openModalBusiness = () => {
    modal.setIsOpen({ isOpen: true, typeForm: "business" });
  };
  const openModalJob = () => {
    modal.setIsOpen({ isOpen: true, typeForm: "job" });
  };
  const openModalContact = () => {
    modal.setIsOpen({ isOpen: true, typeForm: "contact" });
  };

  return (
    <div className="contact-hero__wrapper header-color--light">
      <section className="contact">
        <Grid.Container fluid>
          <Grid.Row>
            <Grid.Cell
              mobileColumns={4}
              columns={10}
              offset={2}
              className="cell"
            >
              <SayHello />
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row className="content">
            <Grid.Cell
              mobileColumns={4}
              columns={6}
              offset={0}
              className="cell"
            >
              <div className="tex-box">
                <p>
                  Drop us a line, <br /> we would love to hear from you!
                </p>
              </div>
            </Grid.Cell>
            <Grid.Cell
              mobileColumns={4}
              columns={6}
              offset={0}
              className="cell"
            >
              <div className="links">
                <div className="form-link">
                  <span>Business</span>
                  <button type="button" onClick={openModalBusiness}>
                    I want to start a new project
                  </button>
                </div>
                <div className="form-link">
                  <span>Job</span>
                  <button type="button" onClick={openModalJob}>
                    I’m excited to join the family
                  </button>
                </div>
                <div className="form-link">
                  <span>Good vibes</span>
                  <button type="button" onClick={openModalContact}>
                    I want to say hello
                  </button>
                </div>
              </div>
            </Grid.Cell>
          </Grid.Row>
        </Grid.Container>
      </section>
    </div>
  );
}

export default ContactHero;
